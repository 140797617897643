export interface SDKSettings {
  id: string;
  origin: string;
  display: InlineDisplay | ModalDisplay;
  chat: ChatSettings;
}

export interface LauncherSettings {
  openIconUrl?: string;
  closeIconUrl?: string;
}

export interface ChatSettings {
  bot: any;
  channel: any;
  user: any;
  session?: any;
  googleApiKey?: string;
  css?: string[];
  showDebugConsole: boolean;
  showFilePicker?: boolean;
  showLocationPicker?: boolean;
  placeholder?: string;
  submitLocationText?: string;
  chatServerInstance?: string;
  colorPrimaryBackground?: string;
  colorPrimaryText?: string;
  locale?: string;
  webchatVersion: string;
}

export enum Display {
  Modal = 'modal',
  Inline = 'inline',
}

export interface InlineDisplay {
  mode: Display.Inline;
  containerId: string;
}

export interface ModalDisplay {
  mode: Display.Modal;
  launcher?: LauncherSettings;
}
